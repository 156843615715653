/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import { Helmet } from "react-helmet"
 import "../sass/main.scss"

 import Footer from "../components/footer/footer"
 
 const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
           description
           email
         }
       }
     }
   `)
 
   return (
     <>
       <Helmet>
         <title>{data.site.siteMetadata?.title || `Tyler Gumb`}</title>
         <meta name="description" content={data.siteMetadata?.description || `Description`} />
 
         <link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Space+Mono&display=swap" rel="stylesheet"></link>
       </Helmet>
       
       <div>
         <main>{children}</main>
         <Footer />
       </div>
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 