import React from 'react'

const Button = ({ children, type, link="/" }) => {
    console.log(link);
    return(
        <a href={link} target="_blank" rel="noopener noreferrer">
            <button className={type === "tech" ? "techButton" : type === "footer" ? "footerButton" : "portfolioButton"}>
                {children}
            </button>

        </a>
    )
};

export default Button;