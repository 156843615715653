import React from "react";

import Button from "../buttons/button";

const Footer = () => (
  <div className="footer">
    <div className="footer__sticky">
    <p>I'd love to hear from you.</p>
      <Button link="mailto: tgumb100@gmail.com" type="footer">
        Get in Touch
      </Button>
    </div>
  </div>
);

export default Footer;
